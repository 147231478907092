import { ApplicationContextInput } from '@ms/yammer-telemetry';
import {
  getDecodedNetworkId,
  getDecodedUserId,
  getTelemetryClientConfig,
  getTelemetryHostingConfig,
} from '@ms/yammer-telemetry-store';

type GetApplicationContextInput = () => ApplicationContextInput;
export const getApplicationContextInput: GetApplicationContextInput = () => {
  const { yammerAppClientId, telemetryClientId, appInstanceId, loadedAt, gitRevision } = getTelemetryClientConfig();
  const { hostingEnvironment } = getTelemetryHostingConfig();
  const userId = getDecodedUserId();
  const networkId = getDecodedNetworkId();

  return {
    ClientId: yammerAppClientId,
    first_party_client: telemetryClientId,
    client_load_id: appInstanceId,
    client_load_time: loadedAt.toString(),
    yamjs_revision: gitRevision,
    environment: hostingEnvironment,
    ...(userId && { UserId: userId }),
    ...(networkId && { NetworkId: networkId }),
  };
};
