import { LogEvent, LogEventInput, LogEvents, ReportLogEventsInput } from '@ms/yammer-telemetry';

import { filterNullishProperties } from './filterUndefinedEventPropertiesInput';
import { getApplicationContextInput } from './getApplicationContextInput';
import { getLogEventInput } from './getTelemetryEventInput';

type GetLogEventInputWithContext = (event: LogEvent) => Promise<LogEventInput>;
export const getLogEventInputWithContext: GetLogEventInputWithContext = async (event) => {
  const logEventInput = await getLogEventInput(event);
  const {
    ClientId,
    first_party_client,
    client_load_id,
    client_load_time,
    yamjs_revision,
    environment,
    UserId,
    NetworkId,
  } = getApplicationContextInput();

  return {
    ...logEventInput,
    ...(ClientId && { ClientId }),
    ...(UserId && { UserId: Number(UserId) }),
    ...(NetworkId && { NetworkId: Number(NetworkId) }),
    Parameters: filterNullishProperties({
      ...logEventInput.Parameters,
      first_party_client,
      client_load_id,
      client_load_time,
      yamjs_revision,
      environment,
    }),
  };
};

type GetReportLogEventsInput = (logEvents: LogEvents) => Promise<ReportLogEventsInput>;
export const getReportLogEventsInput: GetReportLogEventsInput = async (logEvents) => {
  const { errorEvents, performanceEvents, infoEvents } = logEvents;

  return {
    errorEvents: await Promise.all(errorEvents.map((event) => getLogEventInputWithContext(event))),
    infoEvents: await Promise.all(infoEvents.map((event) => getLogEventInputWithContext(event))),
    performanceEvents: await Promise.all(performanceEvents.map((event) => getLogEventInputWithContext(event))),
  };
};
