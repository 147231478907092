import type { RequestInitiator } from '@ms/yammer-telemetry-support';

import { ApiRequest } from './types';

export class ApiError extends Error {
  public requestType: string;
  public apiName?: string;
  public status?: number;
  public initiator?: RequestInitiator;
  public requestId?: string | null;
  public afdRef?: string | null;
  public traceId?: string | null;
  public wwwAuthenticate?: string | null;

  constructor(request: ApiRequest, error: Error | string, status?: number, responseHeaders?: Headers) {
    super();

    this.name = 'ApiError';
    this.apiName = request.apiName;
    this.message = error instanceof Error ? error.message : error;
    this.requestType = request.requestType;
    this.stack = new Error().stack;
    this.status = status;
    this.initiator = request.initiator;
    this.requestId = responseHeaders?.get('X-Request-Id') || responseHeaders?.get('request-id');
    this.afdRef = responseHeaders?.get('X-MSEdge-Ref');
    this.traceId = responseHeaders?.get('x-trace-id');
    this.wwwAuthenticate = responseHeaders?.get('www-authenticate');

    Object.setPrototypeOf(this, ApiError.prototype);
  }
}

type IsApiError = (error: unknown) => error is ApiError;
export const isApiError: IsApiError = (error): error is ApiError => (error ? error instanceof ApiError : false);
