import { AnalyticsEvent } from '../events/types';

type TranslateLegacyEventEventsProperties = (properties: AnalyticsEvent['properties']) => AnalyticsEvent['properties'];
const translateLegacyEventEventsProperties: TranslateLegacyEventEventsProperties = (properties) => {
  const { broadcastId, groupId, isEmbeddable, ...otherProperties } = properties;

  return {
    ...otherProperties,
    broadcast_id: broadcastId,
    group_id: groupId,
    embeddable_player: isEmbeddable,
  };
};

// Some events have legacy properties that need to be maintained for compatibility
export const translateLegacyEventsProperties = (event: AnalyticsEvent): AnalyticsEvent['properties'] => {
  let translatedProperties = event.properties;

  if (event.name === 'visit_broadcast_page') {
    translatedProperties = translateLegacyEventEventsProperties(translatedProperties);
  }

  return translatedProperties;
};
