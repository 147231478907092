import { LogEventInput, ReportLogEventsInput } from '@ms/yammer-telemetry';

import { getEventsBatchInfo } from './getEventsBatchInfo';

type BatchReportLogEventsInput = (
  reportLogEventsInput: ReportLogEventsInput,
  batchSizeInBytes: number
) => ReportLogEventsInput[];
export const batchReportLogEventsInput: BatchReportLogEventsInput = (reportLogEventsInput, batchSizeInBytes) => {
  const eventBatches: ReportLogEventsInput[] = [];
  const errorEventsToBeBatched = reportLogEventsInput.errorEvents;
  const performanceEventsToBeBatched = reportLogEventsInput.performanceEvents;
  const infoEventsToBeBatched = reportLogEventsInput.infoEvents;
  let errorStartIndex = 0;
  let performanceStartIndex = 0;
  let infoStartIndex = 0;

  while (
    (errorEventsToBeBatched && errorStartIndex < errorEventsToBeBatched.length) ||
    (performanceEventsToBeBatched && performanceStartIndex < performanceEventsToBeBatched.length) ||
    (infoEventsToBeBatched && infoStartIndex < infoEventsToBeBatched.length)
  ) {
    const errorEvents: LogEventInput[] = [];
    const performanceEvents: LogEventInput[] = [];
    const infoEvents: LogEventInput[] = [];

    let logEventsCumulativeSize = 0;

    ({ eventsEndIndex: errorStartIndex, eventsCumulativeSize: logEventsCumulativeSize } = updateLogEventsInputBatches(
      errorEvents,
      errorStartIndex,
      batchSizeInBytes,
      logEventsCumulativeSize,
      errorEventsToBeBatched
    ));

    ({ eventsEndIndex: performanceStartIndex, eventsCumulativeSize: logEventsCumulativeSize } =
      updateLogEventsInputBatches(
        performanceEvents,
        performanceStartIndex,
        batchSizeInBytes,
        logEventsCumulativeSize,
        performanceEventsToBeBatched
      ));

    ({ eventsEndIndex: infoStartIndex, eventsCumulativeSize: logEventsCumulativeSize } = updateLogEventsInputBatches(
      infoEvents,
      infoStartIndex,
      batchSizeInBytes,
      logEventsCumulativeSize,
      infoEventsToBeBatched
    ));

    eventBatches.push({
      errorEvents,
      performanceEvents,
      infoEvents,
    });
  }

  return eventBatches;
};

const updateLogEventsInputBatches = (
  logEvents: LogEventInput[],
  startIndex: number,
  batchSizeInBytes: number,
  intialCumulativeSize: number,
  eventsToBeBatched?: LogEventInput[]
) => {
  let eventsEndIndex = startIndex;
  let cumulativeSizeInBytes = 0;
  let eventsCumulativeSize = 0;
  const availableBatchSizeInBytes = batchSizeInBytes - intialCumulativeSize;
  if (eventsToBeBatched && startIndex < eventsToBeBatched.length) {
    ({ eventsEndIndex, cumulativeSizeInBytes } = getEventsBatchInfo(
      eventsToBeBatched,
      startIndex,
      availableBatchSizeInBytes
    ));
    logEvents.push(...eventsToBeBatched.slice(startIndex, eventsEndIndex));
    eventsCumulativeSize = intialCumulativeSize + cumulativeSizeInBytes;
  }

  return { eventsEndIndex, eventsCumulativeSize };
};
