import { AnalyticsEventInput, AnalyticsV2EventInput } from '@ms/yammer-telemetry';

import { getEventsBatchInfo } from './getEventsBatchInfo';

type EventInput = AnalyticsEventInput | AnalyticsV2EventInput;

type BatchReportAnalyticsEventInputs = <T extends EventInput>(eventInputs: T[], batchSizeInBytes: number) => T[][];
export const batchReportAnalyticsEventInputs: BatchReportAnalyticsEventInputs = <T>(
  eventInputs: T[],
  batchSizeInBytes: number
) => {
  const eventInputBatches: T[][] = [];
  const analyticsEventInputsToBeBatched = eventInputs;
  let startIndex = 0;
  if (analyticsEventInputsToBeBatched && analyticsEventInputsToBeBatched.length > 0) {
    while (startIndex < analyticsEventInputsToBeBatched.length) {
      const { eventsEndIndex } = getEventsBatchInfo(analyticsEventInputsToBeBatched, startIndex, batchSizeInBytes);
      eventInputBatches.push(analyticsEventInputsToBeBatched.slice(startIndex, eventsEndIndex));
      startIndex = eventsEndIndex;
    }
  }

  return eventInputBatches;
};
